import {
  arrayRemove,
  arrayUnion,
  getDoc,
  increment,
  writeBatch,
} from 'firebase/firestore/lite';

import { firebaseFireStore } from '../config/firebaseFirestore';
const moment = require('moment');

export const musicartLikeNFT = async (
  like,
  tokenId,
  isMultiple,
  nftId,
  userId,
) => {
  try {
    //updating db
    let batch = writeBatch(firebaseFireStore);
    let currentEleId = isMultiple ? nftId + '-MultipleNft-' + tokenId : tokenId;

    let userLikeRef = doc(
      firebaseFireStore,
      'musicartUserData',
      userId,
      'userDetails',
      'likedNfts',
    );

    const doc = await getDoc(userLikeRef);
    //doing fast update
    const method = doc.exists ? 'update' : 'set';
    if (like) {
      userLikeRef[method]({
        likedNfts: arrayUnion(currentEleId),
      });
    } else {
      userLikeRef[method]({
        likedNfts: arrayRemove(currentEleId),
      });
    }

    let likeRef;
    let likeCountRef = doc(firebaseFireStore, 'musicartNfts', tokenId);

    if (isMultiple) {
      likeRef = doc(
        firebaseFireStore,
        'musicartNfts',
        tokenId,
        'multipleData',
        nftId,
        'likedBy',
        userId,
      );
    } else {
      likeRef = doc(
        firebaseFireStore,
        'musicartNfts',
        tokenId,
        'likedBy',
        userId,
      );
    }

    let likeDoc = await getDoc(likeRef);

    if (like && !likeDoc.exists) {
      //like
      batch.set(likeRef, {
        userId: userId,
        timestamp: moment().format('x'),
      });

      if (isMultiple) {
        let keyProp = `multipleNftDetails.` + nftId + '.likes';
        batch.update(likeCountRef, {
          [keyProp]: increment(1),
        });
      } else {
        batch.update(likeCountRef, {
          likes: increment(1),
        });
      }
    } else if (!like && likeDoc.exists) {
      //unlike

      batch.delete(likeRef);

      if (isMultiple) {
        let keyProp = `multipleNftDetails.` + nftId + '.likes';
        batch.update(likeCountRef, {
          [keyProp]: increment(-1),
        });
      } else {
        batch.update(likeCountRef, {
          likes: increment(-1),
        });
      }
    }

    await batch.commit();

    let returnObj = {
      status: 'success',
      msg: 'Operation completed successfully',
    };
    return returnObj;
  } catch (err) {
    let returnObj = {
      status: 'error',
      msg: err.message ? err.message : 'An error occured',
    };
    return returnObj;
  }
};
