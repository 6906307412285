import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import moment from 'moment';
import Slider from 'react-slick';
import Tooltip from '@material-ui/core/Tooltip';
import { toast } from 'react-toastify';
import { musicartLikeNFT } from '../../services/likeNft';
import { countDecimals } from '../../services/common-query';
import { calculateNfts } from '../../helpers/regex';
import { doc, getDoc } from 'firebase/firestore/lite';
import { firebaseFireStore } from '../../config/firebaseFirestore';
import router from 'next/router';
import { httpsCallable } from 'firebase/functions';
import { firebaseFunctions } from '../../config/firebaseFunctions';
import { AspectRatio } from 'react-aspect-ratio';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import ReactPlayer from 'react-player/lazy';
import AudioComponent from './AudioComponent';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  dotsClass: 'slick-dots slick-thumb',

  appendDots: (dots) => (
    <div
      style={{
        marginBottom: 34,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        height: 20,
        alignItems: 'flex-start',
      }}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
        }}
        style={{ cursor: 'default' }}
      >
        <ul className="discover-dots-ul"> {dots} </ul>
      </div>
    </div>
  ),
  customPaging: (i) => <div className="dots-discover"></div>,
};

export default function NftCard(props) {
  const { ele, fetchArtist = true } = props;
  const userDataRedux = useSelector((state) => state.authReducer.userData);
  const bsvRate = useSelector((state) => state.bsvRateReducer.bsvRate);
  const ethRate = useSelector((state) => state.bsvRateReducer.ethRate);
  const [likedNfts, setlikedNfts] = useState([]);
  const [artistDetails, setartistDetails] = useState(ele.artistDetail);
  const isMobile = useMediaQuery('(min-width:768px) and (max-width:1024px)');
  const ellipseslength = isMobile ? 15 : 28;
  const classes = useStyles();
  const isLoading = props.ele?.tokenName === 'loading';

  const toastId = React.useRef(null);
  useEffect(() => {
    if (
      fetchArtist &&
      props.ele &&
      props.ele?.artistId &&
      !props.ele.artistId.includes('loading')
    ) {
      getArtistDetails();
    }
  }, [props.ele?.artistId]);

  useEffect(() => {
    if (userDataRedux && userDataRedux.uid) {
      getMarketPlaceLikes();
    }
  }, [userDataRedux]);

  const getArtistDetails = async () => {
    let dbRef = await getDoc(
      doc(firebaseFireStore, 'musicartArtists', props.ele?.artistId),
    );

    if (dbRef.exists && dbRef.data()) {
      setartistDetails({ ...dbRef.data(), artistId: dbRef.id });
    }
  };

  const getMarketPlaceLikes = async () => {
    let dataRef = await getDoc(
      doc(
        firebaseFireStore,
        'musicartUserData',
        userDataRedux.uid,
        'userDetails',
        'likedNfts',
      ),
    );

    if (dataRef.exists) {
      if (dataRef.data() && dataRef.data()?.likedNfts) {
        setlikedNfts(dataRef.data().likedNfts);
      }
    }
  };

  let nftLikedStatus = likedNfts && likedNfts.includes(props.ele?.tokenId);
  function showPic(event) {
    setTimeout(() => {
      const matches = document.querySelectorAll(`#${event.target?.alt}`);
      if (matches) {
        matches.forEach((match) => {
          match.style.opacity = 0;
        });
        if (
          document.getElementById(event.target.alt) &&
          document.getElementById(event.target.alt).style
        ) {
          document.getElementById(event.target.alt).style.opacity = 0;
        }
      }
    }, 500);
  }
  const likeFuntion = async (e) => {
    e.stopPropagation();
    if (!userDataRedux) {
      router.push(`/auth/login?page=nft/${props.ele?.tokenId}`);
    } else {
      let modifiedArr = [...likedNfts];
      let modifiedArr2 = [...props.nftDataArr];
      let inc = true;

      let currentEleId = props.ele?.tokenId;

      if (modifiedArr.includes(currentEleId)) {
        inc = false;
        modifiedArr.splice(modifiedArr.indexOf(currentEleId), 1);

        ele.likes = ele.likes - 1;
      } else {
        modifiedArr.push(currentEleId);
        if (ele.likes) {
          ele.likes = ele.likes + 1;
        } else {
          ele.likes = 1;
        }
      }
      //updating firestore
      // let functions = firebase.functions();
      // let likeAPI = functions.httpsCallable("musicartLikeNFT");
      let likeAPI = httpsCallable(firebaseFunctions, 'musicartLikeNFT');
      musicartLikeNFT(
        inc,
        props.ele?.tokenId,
        false,
        null,
        userDataRedux && userDataRedux.uid ? userDataRedux.uid : '',
      );
      setlikedNfts(modifiedArr);
      props.setnftDataArr(modifiedArr2);
      if (props.reloadlikednfts) {
        props.reloadlikednfts(props.ele.tokenId);
      }

      await likeAPI({
        like: inc,
        tokenId: props.ele?.tokenId,
        nftId: null,
        isMultiple: false,
      }).then((data) => {});
    }
  };

  const spotifyUrlExist = () => {
    if (
      artistDetails?.spotify?.indexOf('https://') == 0 ||
      props.ele?.spotify?.indexOf('https://') == 0
    ) {
      return true;
    } else return false;
  };

  const appleUrlExist = () => {
    if (
      artistDetails?.apple?.indexOf('https://') == 0 ||
      props.ele?.spotify?.indexOf('https://') == 0 ||
      props.ele?.appleMusic?.indexOf('https://') == 0
    ) {
      return true;
    } else return false;
  };
  return (
    <>
      <div
        key={props.index + '_spec_sdsd'}
        className="card cursor-pointer"
        style={{ display: 'block', minHeight: 400 }}
      >
        <div className="card__cover" style={{ display: 'block' }}>
          <div
            onClick={(e) => {
              router.push('/nft/' + props.ele?.tokenId);
              return <p>Redirecting...</p>;
            }}
          >
            {(() => {
              if (props.ele.nftType === 'Audio') {
                return (
                  <AudioComponent
                    nftData={props.ele}
                    index={props.index}
                    category={props.category}
                  />
                );
              } else {
                return (
                  <div style={{ display: 'block' }} className="link-flex-res">
                    <Slider {...settings}>
                      {(() => {
                        if (
                          !props.ele?.icons ||
                          props.ele?.icons.length === 0
                        ) {
                          return (
                            <div
                              id={'skeletonasdasd'}
                              className="relative rounded-[22px] imgcover_con skeleton-moon relative z-50  w-full h-full"
                            />
                          );
                        } else {
                          return (
                            props.ele?.icons &&
                            props.ele?.icons.map((ele, index) => {
                              const skeletonID =
                                'idofskeleton' + props.ele?.tokenId;

                              if (ele.includes('_video')) {
                                return (
                                  <div key={ele + index}>
                                    <AspectRatio
                                      ratio="1/1"
                                      style={{
                                        width: '100%',
                                        borderRadius: '18px',
                                      }}
                                    >
                                      <div className="flex w-full h-full">
                                        <AspectRatio
                                          ratio="1/1"
                                          style={{
                                            width: '100%',
                                            borderRadius: '18px',
                                            position: 'relative',
                                          }}
                                        >
                                          {' '}
                                          <div
                                            id={skeletonID}
                                            style={{ borderRadius: '18px' }}
                                            className="skeleton-moon  "
                                          />
                                        </AspectRatio>
                                        <div
                                          alt={skeletonID}
                                          className="top-0 left-0 w-full h-full object-cover"
                                          width="100%"
                                          height="100%"
                                          style={{
                                            position: 'absolute',
                                            borderRadius: '18px',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          <ReactPlayer
                                            playsinline={true}
                                            height="100%"
                                            width="100%"
                                            url={ele}
                                            controls={true}
                                            playing={true}
                                            muted={true}
                                            loop={true}
                                          />
                                        </div>
                                      </div>
                                    </AspectRatio>
                                  </div>
                                );
                              } else {
                                return (
                                  <div key={ele + index}>
                                    <AspectRatio
                                      ratio="1/1"
                                      style={{
                                        width: '100%',
                                        borderRadius: '18px',
                                      }}
                                    >
                                      <div className="flex w-full h-full">
                                        <AspectRatio
                                          ratio="1/1"
                                          style={{
                                            width: '100%',
                                            borderRadius: '18px',
                                            position: 'relative',
                                          }}
                                        >
                                          {' '}
                                          <div
                                            id={skeletonID}
                                            style={{ borderRadius: '18px' }}
                                            className="skeleton-moon  "
                                          />
                                        </AspectRatio>

                                        <img
                                          key={ele + 'imgNFTT'}
                                          alt={skeletonID}
                                          // onLoad={showPic}
                                          src={
                                            ele
                                              ? ele
                                              : '/img/cover/cover_260x260.jpg'
                                          }
                                          placeholder="/img/cover/cover_260x260.jpg"
                                          // style={{borderRadius:'18px'}}
                                          className="top-0 left-0 w-full h-full object-cover"
                                          width="100%"
                                          height="100%"
                                          style={{
                                            position: 'absolute',
                                            borderRadius: '18px',
                                          }}
                                        />
                                      </div>
                                    </AspectRatio>
                                  </div>
                                );
                              }
                            })
                          );
                        }
                      })()}
                    </Slider>
                  </div>
                );
              }
            })()}
          </div>
        </div>
        <div>
          <div className="card_head1" style={{ marginBottom: 15 }}>
            <div
              style={{
                fontSize: 15,
                display: 'flex',
                display: 'inline-flex',
                alignItems: 'center',
              }}
            >
              <div
                className="skeleton-moon"
                style={{
                  opacity: props.ele?.tokenName
                    ? props.ele.tokenName === 'loading'
                      ? 1
                      : 0
                    : 1,
                  position: 'absolute',
                  zIndex: 500,
                  width: 40,
                  transform: 'translateX(-10px)',
                  height: 21,
                  borderRadius: 8,
                }}
              />
              <b>
                {ele?.isMultiple ? calculateNfts(ele.multipleNftDetails) : 1}/
                {ele?.nftQuantity ? parseInt(ele?.nftQuantity) : 1}
              </b>
            </div>

            <div>
              {props.ele && props.ele?.artistId && artistDetails && (
                <div
                  className="flex"
                  style={{ marginTop: '-3px', alignItems: 'center' }}
                >
                  {(props.ele?.spotify ||
                    (artistDetails.spotify && spotifyUrlExist())) && (
                    <div className="asset_spotify flex items-center text-sm text-blueGray-700">
                      <Tooltip
                        title="Spotify"
                        classes={classes}
                        arrow
                        placement="top"
                      >
                        <a
                          className="flex justify-around px-2"
                          href={
                            props.ele?.spotify
                              ? props.ele?.spotify
                              : artistDetails.spotify
                          }
                          rel="noreferrer"
                          target="_blank"
                        >
                          <svg
                            style={{ height: 18 }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 186.845 186.845"
                          >
                            <g>
                              <path
                                d="M128.875,120.962c-31.094-14.37-74.616-8.014-76.453-7.737c-4.096,0.619-6.915,4.44-6.296,8.536
                              c0.619,4.096,4.443,6.912,8.536,6.296c0.406-0.062,40.867-5.982,67.92,6.521c1.018,0.471,2.089,0.694,3.142,0.694
                              c2.834-0.001,5.546-1.614,6.813-4.355C134.274,127.157,132.635,122.7,128.875,120.962z"
                              />
                              <path
                                d="M137.614,93.953c-35.313-16.319-84.833-9.087-86.924-8.772c-4.094,0.619-6.911,4.438-6.294,8.532
                               c0.616,4.095,4.438,6.916,8.531,6.301c0.468-0.071,47.206-6.857,78.394,7.556c1.02,0.471,2.089,0.694,3.142,0.694
                              c2.834-0.001,5.546-1.614,6.814-4.356C143.014,100.148,141.374,95.691,137.614,93.953z"
                              />
                              <path
                                d="M143.49,65.736c-39.006-18.027-93.79-10.028-96.103-9.679c-4.094,0.619-6.911,4.438-6.294,8.532s4.44,6.919,8.531,6.3
                               c0.523-0.079,52.691-7.657,87.573,8.463c1.018,0.471,2.089,0.694,3.142,0.694c2.834,0,5.546-1.614,6.813-4.355
                               C148.89,71.93,147.25,67.474,143.49,65.736z"
                              />
                              <path
                                d="M93.423,0.001C41.909,0.001,0,41.909,0,93.42c0,51.514,41.909,93.424,93.423,93.424c51.513,0,93.422-41.91,93.422-93.424
                               C186.845,41.909,144.936,0.001,93.423,0.001z M93.423,171.844C50.18,171.844,15,136.664,15,93.42
                                c0-43.241,35.18-78.42,78.423-78.42c43.242,0,78.422,35.179,78.422,78.42C171.845,136.664,136.665,171.844,93.423,171.844z"
                              />
                            </g>
                          </svg>
                        </a>
                      </Tooltip>
                    </div>
                  )}

                  {((props.ele && (props.ele.apple || props.ele.appleMusic)) ||
                    artistDetails.apple) &&
                    appleUrlExist() && (
                      <div className="asset_apple flex items-center text-sm text-blueGray-700">
                        <Tooltip
                          title="Apple"
                          classes={classes}
                          arrow
                          placement="top"
                        >
                          <a
                            className="flex justify-around px-2"
                            href={
                              props.ele &&
                              (props.ele.apple || props.ele.appleMusic)
                                ? props.ele.apple
                                  ? props.ele.apple
                                  : props.ele.appleMusic
                                : artistDetails.apple
                            }
                            rel="noreferrer"
                            target="_blank"
                          >
                            <svg
                              style={{ height: 18 }}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 291.538 291.538"
                            >
                              <g>
                                <path
                                  d="M188.373,44.308c10.97-11.744,18.39-28.049,16.359-44.308c-15.804,0.564-34.958,9.277-46.302,21.02
                            c-10.169,10.396-19.072,27.011-16.696,42.951C159.369,65.164,177.385,56.033,188.373,44.308z M222.03,156.593
                            c-0.337-36.451,28.859-53.958,30.152-54.804c-16.414-24.753-41.977-28.14-51.081-28.522c-21.767-2.285-42.442,13.182-53.493,13.182
                            c-11.006,0-28.03-12.882-46.083-12.536c-23.733,0.391-45.601,14.211-57.79,36.114C19.081,154.09,37.416,219.363,61.44,255.104
                            c11.744,17.497,25.727,37.125,44.089,36.415c17.725-0.71,24.407-11.789,45.792-11.789c21.394,0,27.384,11.789,46.101,11.434
                            c19.045-0.355,31.098-17.834,42.742-35.386c13.473-20.292,19.027-39.938,19.345-40.93
                            C259.1,214.611,222.421,200.182,222.03,156.593z"
                                />
                              </g>
                            </svg>
                          </a>
                        </Tooltip>
                      </div>
                    )}
                </div>
              )}
            </div>

            <Tooltip
              title={
                nftLikedStatus ? 'Remove from favorites' : 'Add to favorites'
              }
            >
              <button
                type="button"
                onClick={likeFuntion}
                style={{
                  fontSize: 15,
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: -3,
                }}
              >
                <div
                  className="skeleton-moon"
                  style={{
                    opacity: props.ele?.tokenName
                      ? props.ele.tokenName === 'loading'
                        ? 1
                        : 0
                      : 1,
                    position: 'absolute',
                    width: 26,
                    height: 26,
                    borderRadius: 8,
                    fontSize: 13,
                  }}
                />
                <div
                  className="card__likes"
                  style={{ fontSize: 15, marginRight: 3, marginTop: 0 }}
                >
                  {nftLikedStatus ? (
                    <i style={{ fontSize: 15 }} className="fas fa-heart" />
                  ) : (
                    <i style={{ fontSize: 15 }} className="far fa-heart" />
                  )}
                </div>
                <div style={{ fontSize: 15, display: 'flex' }}>
                  {props.ele?.likes ? props.ele?.likes : ''}
                </div>
              </button>
            </Tooltip>
          </div>
        </div>

        <div>
          <div style={{ width: '100%' }}>
            {props.ele && props.ele?.artistId && artistDetails ? (
              <div className="share">
                <Link prefetch={false} href={'/artist/' + props.ele?.artistId}>
                  <a style={{ display: 'block' }}>
                    <div className={'card__author'} style={{ marginRight: 5 }}>
                      <div className="author__avatar1">
                        <img
                          src={
                            artistDetails && artistDetails.photoUrl
                              ? artistDetails.photoUrl + '_250x250?alt=media'
                              : '/img/avatars/avatar.jpg'
                          }
                          alt="owner-image"
                        />
                      </div>
                    </div>
                  </a>
                </Link>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Link prefetch={false} href={'/nft/' + props.ele?.tokenId}>
                    <a
                      style={{
                        display: 'block',
                      }}
                    >
                      <div>
                        <div
                          className="skeleton-moon"
                          style={{
                            opacity: props.ele?.tokenName
                              ? props.ele.tokenName === 'loading'
                                ? 1
                                : 0
                              : 1,
                            position: 'absolute',
                            width: 140,
                            height: 25,
                            borderRadius: 8,
                          }}
                        />
                        <Tooltip title={props.ele?.tokenName} classes={classes}>
                          <b
                            className="relative"
                            style={{
                              display: 'block',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: 180,
                              height: 20,
                            }}
                          >
                            {props.ele?.tokenName
                              ? props.ele?.tokenName.length > ellipseslength
                                ? props.ele?.tokenName.substring(
                                    0,
                                    ellipseslength,
                                  ) + '...'
                                : props.ele?.tokenName
                              : '-'}
                          </b>
                        </Tooltip>
                      </div>
                    </a>
                  </Link>
                  <Link
                    prefetch={false}
                    href={'/artist/' + props.ele?.artistId}
                  >
                    <a
                      style={{
                        display: 'block',
                      }}
                    >
                      <div>
                        <div
                          className="skeleton-moon"
                          style={{
                            opacity: props.ele?.tokenName
                              ? props.ele.tokenName === 'loading'
                                ? 1
                                : 0
                              : 1,
                            position: 'absolute',
                            width: 140,
                            height: 25,
                            borderRadius: 8,
                          }}
                        />
                      </div>

                      <Tooltip title={artistDetails.name} classes={classes}>
                        <span
                          style={{
                            display: 'block',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                          className="text_in_one_line"
                        >
                          {artistDetails && artistDetails.name
                            ? artistDetails.name.length > ellipseslength
                              ? artistDetails.name.substring(
                                  0,
                                  ellipseslength,
                                ) + '...'
                              : artistDetails.name
                            : '-'}
                        </span>
                      </Tooltip>
                    </a>
                  </Link>
                </div>
              </div>
            ) : (
              <div style={{ display: 'block' }}>
                <div className={'card__author relative'}>
                  <div className="author__avatar1">
                    <div
                      style={{ borderRadius: 7, width: '90%', height: '90%' }}
                    />
                  </div>
                  <div
                    className="skeleton-moon"
                    style={{
                      opacity: artistDetails?.name ? 1 : 1,
                      position: 'absolute',
                      width: 140,
                      height: 20,
                      borderRadius: 8,
                      left: 44,
                    }}
                  />
                  <span className="text_in_one_line ml-1">unknowns</span>
                </div>
              </div>
            )}
          </div>
          <div className="card__info">
            <Link
              prefetch={false}
              href={'/nft/' + props.ele?.tokenId}
              key={'marketNFT' + props.index}
            >
              <div className=" card_price-wt">
                <div className="card_head1">
                  <div style={{ fontSize: 15, display: 'flex', flexShrink: 5 }}>
                    <div
                      className="skeleton-moon cursor-pointer"
                      style={{
                        opacity: props.ele?.tokenName
                          ? props.ele.tokenName === 'loading'
                            ? 1
                            : 0
                          : 1,
                        position: 'absolute',
                        width: 120,
                        height: 20,
                        borderRadius: 8,
                      }}
                    />
                    {props?.ele?.tokenName !== 'loading' ? (
                      <>
                        {' '}
                        {props.ele?.isMultiple
                          ? 'Starting from'
                          : props.ele?.nftSaleType &&
                            props.ele?.nftSaleType === 'Auctioning'
                          ? 'Minimum bid'
                          : 'Current price'}
                      </>
                    ) : (
                      <div
                        className="skeleton-moon"
                        style={{
                          opacity: props.ele?.tokenName
                            ? props.ele.tokenName === 'loading'
                              ? 1
                              : 0
                            : 1,
                          position: 'relative',
                          width: 120,
                          height: 20,
                          borderRadius: 8,
                        }}
                      />
                    )}
                  </div>

                  {props.ele?.nftSaleType &&
                    props.ele?.nftSaleType === 'Auctioning' && (
                      <div
                        style={{
                          position: 'relative',
                          right: 5.5,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div className="clock-svg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="M18.3,8.59l.91-.9a1,1,0,0,0-1.42-1.42l-.9.91a8,8,0,0,0-9.79,0l-.91-.92A1,1,0,0,0,4.77,7.69l.92.91A7.92,7.92,0,0,0,4,13.5,8,8,0,1,0,18.3,8.59ZM12,19.5a6,6,0,1,1,6-6A6,6,0,0,1,12,19.5Zm-2-15h4a1,1,0,0,0,0-2H10a1,1,0,0,0,0,2Zm3,6a1,1,0,0,0-2,0v1.89a1.5,1.5,0,1,0,2,0Z" />
                          </svg>
                        </div>

                        <div
                          className="card_head1Text"
                          style={{ display: 'flex' }}
                        >
                          {Number(moment().format('x')) >=
                          Number(props.ele?.auctionEndTime) ? (
                            <>Closed</>
                          ) : (
                            <>
                              {moment(
                                Number(props.ele?.auctionEndTime),
                              ).fromNow(
                                // moment.updateLocale('en', {
                                //   relativeTime: {
                                //     future: 'in %s',
                                //     past: '%s ',
                                //     // s: 'sec',
                                //     // m: '%dm',
                                //     // mm: '%dm',
                                //     // h: '%dh',
                                //     // hh: '%dh',
                                //     // d: '%d day',
                                //     // dd: '%d days',
                                //     // M: '%d month',
                                //     // MM: '%d months',
                                //     // y: '%d year',
                                //     // yy: '%d years',
                                //   },
                                // }
                                // ),
                                true,
                              ) + ' '}
                              left
                            </>
                          )}
                        </div>
                      </div>
                    )}
                </div>

                <div className="flex justify-between w-full mt-2 font-bold">
                  <div
                    className="bsv_size_size"
                    style={{ display: 'flex', cursor: 'pointer' }}
                  >
                    <div
                      className="skeleton-moon"
                      style={{
                        opacity: props.ele?.tokenName
                          ? props.ele.tokenName === 'loading'
                            ? 1
                            : 0
                          : 1,
                        position: 'absolute',
                        width: 'calc(100% - 35px)',
                        height: 20,
                        transform: 'translateX(-3px)',
                        borderRadius: 8,
                        bottom: 18,
                      }}
                    />
                    {/* //dollar */}
                    <span className="font-bold">
                      $
                      {(() => {
                        if (props.ele?.isMultiple) {
                          return props.ele?.startingPrice || props.ele?.price
                            ? Number(props.ele?.startingPrice).toFixed(2)
                            : '-';
                        } else {
                          if (
                            props.ele?.nftSaleType &&
                            props.ele?.nftSaleType === 'Auctioning'
                          ) {
                            return props.ele?.minBiddingPrice
                              ? Number(props.ele?.minBiddingPrice).toFixed(2)
                              : '-';
                          } else {
                            return props.ele?.price
                              ? Number(props.ele?.price).toFixed(2)
                              : '-';
                          }
                        }
                      })()}
                    </span>
                    &nbsp;&nbsp;
                    {/* //BSV */}
                    <span className="flex">
                      {bsvRate && !isLoading && (
                        <Tooltip title="BSV" classes={classes}>
                          <div className="relative cursor-pointer">
                            <img
                              className="mr-0-5 ml-1"
                              style={{
                                width: '14px',
                                display: 'unset',
                                position: 'relative',
                                bottom: 1,
                              }}
                              src={'/img/bsvlogo.svg'}
                            />
                          </div>
                        </Tooltip>
                      )}
                      &nbsp;
                      {(() => {
                        if (props.ele?.isMultiple) {
                          let priceInBsv =
                            Number(props.ele?.startingPrice) / bsvRate;

                          return props.ele &&
                            priceInBsv &&
                            Number(priceInBsv) &&
                            countDecimals(Number(priceInBsv)) &&
                            countDecimals(Number(priceInBsv)) > 4
                            ? Number(priceInBsv).toFixed(4)
                            : props.ele && priceInBsv
                            ? priceInBsv
                            : '';
                        } else {
                          if (
                            props.ele?.nftSaleType &&
                            props.ele?.nftSaleType === 'Auctioning'
                          ) {
                            let priceInBsv =
                              Number(props.ele?.minBiddingPrice) / bsvRate;

                            return props.ele &&
                              priceInBsv &&
                              Number(priceInBsv) &&
                              countDecimals(Number(priceInBsv)) &&
                              countDecimals(Number(priceInBsv)) > 4
                              ? Number(priceInBsv).toFixed(4)
                              : props.ele && priceInBsv
                              ? priceInBsv
                              : '';
                          } else {
                            let priceInBsv = Number(props.ele?.price) / bsvRate;

                            return props.ele &&
                              priceInBsv &&
                              Number(priceInBsv) &&
                              countDecimals(Number(priceInBsv)) &&
                              countDecimals(Number(priceInBsv)) > 4
                              ? Number(priceInBsv).toFixed(4)
                              : props.ele && priceInBsv
                              ? priceInBsv
                              : '';
                          }
                        }
                      })()}
                    </span>
                    &nbsp;&nbsp;
                    {/* //ETH */}
                    <span className="flex">
                      {ethRate && !isLoading && (
                        <Tooltip title="ETH" classes={classes}>
                          <div className="relative cursor-pointer">
                            <img
                              className="mr-0-5 ml-1"
                              style={{
                                width: '10px',
                                display: 'unset',
                                position: 'relative',
                                bottom: 1,
                              }}
                              src={'/img/ethlogo.svg'}
                            />
                          </div>
                        </Tooltip>
                      )}
                      &nbsp;
                      {(() => {
                        if (ethRate) {
                          if (props.ele?.isMultiple) {
                            let priceInEth =
                              Number(props.ele?.startingPrice) / ethRate;
                            return priceInEth ? priceInEth.toFixed(4) : '-';
                          } else {
                            if (
                              props.ele?.nftSaleType &&
                              props.ele?.nftSaleType === 'Auctioning'
                            ) {
                              let priceInEth =
                                Number(props.ele?.minBiddingPrice) / ethRate;

                              return priceInEth ? priceInEth.toFixed(4) : '-';
                            } else {
                              let priceInEth =
                                Number(props.ele?.price) / ethRate;

                              return priceInEth ? priceInEth.toFixed(4) : '-';
                            }
                          }
                        }
                      })()}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
